import React from "react";
import {DiscussionEmbed} from 'disqus-react';
import ServiceContainer from "./service-container";

const Reviews = () => (
  <ServiceContainer title="Отзывы">
    <div style={{width: '100%'}}>
      <DiscussionEmbed
        shortname='bath-2'
        config={
          {
            url: "https://bath-2.disqus.com/embed.js",
          }
        }
      />
    </div>
  </ServiceContainer>
);

export default Reviews;
