import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import ServiceContainer from "../components/service-container";
import Gallery from "../components/gallery";
import GallerySlider from "../components/gallery-slider";
import ScrollButton from "../components/scroll-button";
// import { useAuthValue } from "../components/Auth/AuthContext";

import { descriptionBanya } from "../consts/descriptionBanya";

import useWindowSize from "../hooks/useWindowSize";

import banner from "../images/Main/bani-winter.webp";
import Reviews from "../components/reviews";

const Description = () => {

  const size = useWindowSize();

  /**Получаем статус пользователя*/
  // const { currentUser } = useAuthValue();

  return (
    <Layout
      metaContent="Традиционные русские бани по-чёрному и по-серому. Сруб собран без единого гвоздя по технологии наших предков."
      title="Описание затонских бань"
      keywords="затон баня по серому, затон баня по черному баня по серому в барнауле, баня по черному в барнауле"
    >
      {/**Банннер слайдер*/}
      <ScrollButton />
      <div className="banner-container">
        <div className="site-Banner">
          <img src={banner} alt="Баня по-черному" className="banner-img" />
          <div className="Banner-details">
            <h1>Затонские Бани</h1>
            <span className="banner-adress"><i
              className="fa fa-map-marker-alt" />{" "}п. Затон ул.Водников 1е</span><br />
            {/*{*/}
            {/*  currentUser ? (*/}
                <Link to="/order/">Заказать</Link>
              {/*) : (*/}
              {/*  <a href="http://localhost:3000/">Заказать</a>*/}
              {/*)*/}
            {/*}*/}
          </div>
        </div>
      </div>
      {/**Блоки с галлереями фотографий*/}
      {
        descriptionBanya.map((item, index) => (
          <ServiceContainer
            key={item.id}
            title={item.title}
            description={item.desc}
          >
            {
              size.width >= 768
                ?
                <Gallery gallery={item.gallery} />
                :
                <GallerySlider gallery={item.gallery} />
            }
          </ServiceContainer>
        ))
      }
      <div className="order-btn-wrap">
      {/*  {*/}
      {/*    currentUser ? (*/}
            <Link to="/order/">Заказать</Link>
          {/*) : (*/}
          {/*  <a href="http://localhost:3000/">Заказать</a>*/}
          {/*)*/}
        {/*}*/}
      </div>
      <Reviews/>
    </Layout>
  );
};
export default Description;